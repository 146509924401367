/* eslint-disable no-warning-comments */
/** @type {import('tailwindcss').Config} */

const {colors} = require('./tailwind-theme')

const getSpacing = () => {
  const sizes = {}
  for (let i = 0; i <= 100; i++) {
    sizes[i] = `${i * 0.25}rem`
  }
  return sizes
}

module.exports = {
  content: ['./src/**/*.jsx'],
  theme: {
    colors: {
      ...colors,
      transparent: 'transparent',
    },
    spacing: getSpacing,
    extend: {
      screens: {
        lgquizz: '1280px',
        xl: '1328px',
      },
      borderRadius: {
        none: '0',
        sm: '4px',
        md: '8px',
        lg: '12px',
      },
      keyframes: {
        breath: {
          '0%, 100%': {transform: 'scale(1)', opacity: 1},
          '50%': {transform: 'scale(0.8)', opacity: 0.7},
        },
        leftRight: {
          '0%, 100%': {transform: 'translateX(0)'},
          '50%': {transform: 'translateX(-150px)'},
        },
        topBottom: {
          '0%, 100%': {transform: 'translateY(0)'},
          '50%': {transform: 'translateY(-80px)'},
        },
      },
      animation: {
        breath: 'breath 3s ease-in-out infinite',
        leftRight: 'leftRight 3s ease-in-out infinite alternate',
        topBottom: 'topBottom 2.5s ease-in-out infinite alternate',
      },
      boxShadow: {
        inside: 'inset 0 0 0 1px',
      },
    },
  },
  plugins: [require('@tailwindcss/aspect-ratio'), require('@tailwindcss/typography')],
}
